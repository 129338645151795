import React from "react";

const ServiceSection = () => {
    return (
        <section id="services" className="services section-bg">
        <div className="container" data-aos="fade-up">
  
          <div className="section-title">
            <h2>Our Services</h2>
            <p>Check out our services</p>
          </div>
  
          <div className="row">
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box">
              <img src={require("../../resources/images/Online page-rafiki.png")} className="img-fluid" alt="Responsive image"/>
                <div className="icon"><i class="bi bi-1-circle"></i></div>
                <h4><a href="">Custom Website</a></h4>
                <p>Various types of websites like online stores, Learning website, Portfolio and so on </p>
              </div>
            </div>
  
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
              <div className="icon-box">
              <img src={require("../../resources/images/Order ride-pana.png")} className="img-fluid" alt="Responsive image"/>
                <div className="icon"><i className="bx bx-file"></i></div>
                <h4><a href="">Android/ios Apps</a></h4>
                <p>various mobile applications like food delivery app, online stores, Inventory apps and so on</p>
              </div>
            </div>
  
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon-box">
              <img src={require("../../resources/images/Android-amico.png")} className="img-fluid" alt="Responsive image"/>
                <div className="icon"><i className="bx bx-tachometer"></i></div>
                <h4><a href="">Web scrapping</a></h4>
                <p>We can build automated scripts which will collect data from websites and give you complete report according to your need</p>
              </div>
            </div>
  
            <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
              <div className="icon-box">
              <img src={require("../../resources/images/Working-rafiki.png")} className="img-fluid" alt="Responsive image"/>
                <div className="icon"><i className="bx bx-layer"></i></div>
                <h4><a href="">Custom script</a></h4>
                <p>Custom scripts of different tasks like data analysis, transformation etc</p>
              </div>
            </div>
  
          </div>
  
        </div>
      </section>
    );
}

export default ServiceSection;