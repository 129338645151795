import React from "react";
import HeroSection from "../components/portfolio/HeroSection"

const Portfolio = () => {
    return (
        <>
         <HeroSection/>
        </>
    );
}
export default Portfolio;