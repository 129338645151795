import './App.css';
import HomePage from './pages/Homepage';
import { Routes ,Route } from 'react-router-dom';
import React from 'react'
import AboutMePage from './pages/AboutMePage';
import NotFoundPage from './pages/NotFoundPage';
import NavBar from './components/common/navbar'
import Portfolio from './pages/Portfolio';

function App() {
  return (
    <>
    <NavBar/>
    <Routes>
      <Route path="/" element={<HomePage/>}/>
      <Route path="/about" element={<AboutMePage/>}/>
      <Route path="/portfolio" element={<Portfolio/>}/>
      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
    </>
  );
}

export default App;
