import React from "react";
import HomePageTitle from "../components/homepage/HomePageTitle";
import ClientSection from "../components/homepage/ClientSection";
import AboutUs from "../components/homepage/AboutUs";
import ServiceSection from "../components/homepage/ServiceSection";
import CtaSection from "../components/homepage/CtaSection";
import ContactSection from "../components/homepage/ContactSection";

const HomePage = () => {
    return (
    <>
    <HomePageTitle/>
    {/* <ClientSection/> */}
    <AboutUs/>
    <ServiceSection/>
    <ClientSection />
    <ContactSection/>
    </> );
}

export default HomePage;