import React from "react";

const AboutUs = () => {
    return (
        <>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">

                    <div className="section-title">
                        <h2>About Us</h2>
                    </div>

                    <div className="row content">
                        <div className="col-lg-4">
                            <p>
                                Codewat is a startup company which provides various services like
                            </p>
                            <ul>
                                <li><i className="ri-check-double-line"></i> Custom website according to your need</li>
                                <li><i className="ri-check-double-line"></i> Scrapping data from website and generate reprot</li>
                                <li><i className="ri-check-double-line"></i> Apps for your business</li>
                            </ul>
                        </div>
                        <div className="col-lg-4">
                        <img src={require("../../resources/images/juicy-man-programmer-writing-code-and-make-web-design-on-a-pc.gif")} className="img-fluid" alt="Responsive image"/>
                        </div>
                        <div className="col-lg-4 pt-4 pt-lg-0">
                            <p>
                                Grow your business with Codewat and your support is very important to grow our business too.
                                <br/>Please feel free to contact with us. Thank you.
                            </p>
                            <a href="#" className="btn-learn-more">Learn More</a>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default AboutUs