import React from "react";

const ClientSection = () => {
    return (
        <>    
        <section id="clients" className="clients section-bg">
        <div className="section-title">
            <h2>Our Happy Clients</h2>
            <p>Check out our previous works</p>
          </div>
          <div className="row content">
            <div className="col-lg-4">
              {/* <img src={require("../../resources/images/Online page-rafiki.png")} className="img-fluid" alt="Responsive image"/> */}
            </div>
            <div className="col-lg-4">
            <a href="https://www.techmechanical.in">
              <img src={require("../../resources/images/techmechanical_logo.png")} className="img-fluid" alt="Responsive image" href="https://www.techmechanical.in"/>
              </a>
            </div>
            <div className="col-lg-4">
              {/* <img src={require("../../resources/images/Online page-rafiki.png")} className="img-fluid" alt="Responsive image"/> */}
            </div>
            
            </div>
        </section>
        </>
    );
}


export default ClientSection;